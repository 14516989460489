/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-redeclare */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {Redirect, withRouter} from 'react-router-dom';
import {getJobTitleByOrg} from '../../redux/jobTitle/jobTitle.actions';
import ImageCropper from '../../components/cropper';
import clsx from 'clsx';
import {
  getAllManager,
  getAllRole,
  updateUser,
  clearState,
  getOneManager,
  getProfile,
  getNewUsers,
} from '../../redux/userManagement/userManagement.actions';
import {USER_STATE} from '../../redux/userManagement/userManagement.types';
import {
  MInput,
  MSelect,
  MButton,
  ModalTitle,
  MDatepicker,
  MCheckboxItem,
  MCheckboxGroup,
  ModalTitleAgreement,
  MInputMask,
} from '../../components/form';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  CardMedia,
  Grid,
  Menu,
  MenuItem,
  Zoom,
  Badge,
  Paper,
  CircularProgress,
  Link,
  ClickAwayListener,
  Box,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ChevronLeft,
  ExitToAppRounded,
  ExpandMore,
  ExpandLess,
  InfoOutlined,
  Close,
  Star,
  ExitToApp,
  Done,
  Notifications,
  Send,
  Visibility,
  VisibilityOff,
  Crop,
} from '@material-ui/icons';
import {logout} from '../../redux/login/login.actions';
import {clearRefreshToken, updateRefreshToken} from '../../redux/refreshToken/refreshToken.actions';
import {checkPremium} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {checkStatus, acceptTermsAndCondition} from '../../redux/organization/organization.actions';
import {checkSuperadmin, isArray, getTimeDiff, toast, isObject, convDate, checkFileUploadSize, blobToFile, showPopup, parseJwt, impersonateHrMode} from '../../helpers';
import menus from './menus';
import styles from './styles';
import UpgradeAccountModal from '../../pages/upgradeAccount';
import _ from 'lodash';
import {ENDPOINTS, apiService} from '../../config';
import {checkStatus as checkStatusUser, readNotifUser} from './../../redux/userManagement/userManagement.actions';
import {
  checkAmount,
  getInvoicePrice,
} from '../../redux/invoice/invoice.actions';
import {UPGRADE_STATE as STATE} from '../../redux/upgradeAccount/upgradeAccount.types';
import {XENDIT_STATE} from '../../redux/xendit/xendit.types';
import {getInvoiceHistory} from '../../services/invoice';
import photoDefault from '../../assets/img/gamification/photoDefault.png';
import {checkXenditStatus} from '../../redux/xendit/xendit.actions';
// import db from '../../config/firebase';
import {collection, getDocs, onSnapshot, where, doc, query} from 'firebase/firestore';
import moment from 'moment';
import organization from '../../pages/organization';
import pagiiLogo from '../../assets/icon/Pagii/pagii.png';
import pagiiLogoShort from '../../assets/icon/Pagii/pagii-short.png';
import {Autocomplete} from '@material-ui/lab';
import {DropzoneArea} from 'material-ui-dropzone';
import {WITHDRAW_STATE} from '../../redux/withdraw/withdraw.types';
import {clearState as clearWithdraw} from '../../redux/withdraw/withdraw.actions';
import {
  getDivisionByOrg,
  createDivision,
  clearState as clearStateDivision,
} from './../../redux/divisionManagement/divisionManagement.actions';
import {DIVISION_STATE} from '../../redux/divisionManagement/divisionManagement.types';

import axios from 'axios';
import {terms as termsData} from '../policy/terms';
import {policy as policyData} from '../policy/policy';
import {ORGANIZATION_STATE} from '../../redux/organization/organization.types';
import {
  getNotification,
  readNotification,
  clearState as clearStateNotification,
} from './../../redux/notification/notification.actions';
import {NOTIFICATION_STATE} from '../../redux/notification/notification.types';
import ExportFloatingCard from '../export';
import ImportFloatingCard from '../import';
import {updateExportNotification} from '../../redux/exportActivity/exportActivity.actions';
import {REFRESH_TOKEN_STATE} from '../../redux/refreshToken/refreshToken.types';
import {getMessaging, getToken, isSupported} from 'firebase/messaging';

const INVOICE_PERIOD = process.env.REACT_APP_API_INVOICE_PERIOD;
const MEETING_LINK = 'https://calendly.com/pagii/meeting-online';


class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessageEditProfile: false,
      isDrawerOpen: true,
      isMenuOpen: false,
      expandUser: false,
      showUpgradeModal: false,
      showAlert: true,
      showCancelModal: false,
      title: 'Attendance Apps',
      paidleave: true,
      menusList: [],
      anchorEl: null,
      showMethod: null,
      schedule: false,
      invoiceUrl: null,
      orderId: null,
      notifModal: false,
      notif: [],
      userFeature: true,
      profileData: null,
      modalAgreementShow: false,

      formDataProfile: {
        id: null,
        name: null,
        email: null,
        nik: null,
        division_id: null,
        approval_type: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        manager: '',
        join_date: null,
        role_id: '',
        job_title_id: '',
      },

      searchFilterManager: null,
      isEditProfile: false,
      modalFormShowProfile: false,
      showPasswordProfile: false,
      errorMessageProfile: null,
      errorImageSizeProfile: null,
      showCropResultProfileProfile: false,
      isCroppingProfile: false,
      modalDivisionShow: false,
      division_name: null,
      description: null,
      createdDivision: false,

      package_available: null,
      tnc: false,
      isScrolledBottom: false,
      buttonLoading: false,
      loadingRead: false,
      loadingIndex: null,
      isExportFile: true,
      isImportFile: true,
      isRefreshToken: false,
    };
  }

  autocompleteTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })

  getMuiTheme = () => createTheme({
    overrides: {
      MuiTypography: {
        body1: {
          fontSize: 14,
          fontWeight: 400,
          color: '#656464',
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          borderRight: 0,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '0px !important',
          marginRight: '14px',
        },
      },
      MuiSvgIcon: {
        root: {
          fill: '#4E4E4E',
        },
      },
    },
  });

  withdrawalTheme = () => createTheme({
    overrides: {
      MuiTypography: {
        body1: {
          fontSize: 14,
          fontWeight: 600,
          color: '#656464',
        },
      },
    },
  });

  modalTitleTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: 20,
          fontWeight: 600,
          color: '#656464',
        },
      },
    },
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message, isOpen, statusData, showUpgradeModal, isSuperAdmin, showMethod, dueDate, refreshToken, triggerToken} = nextProps;
    const {profileData, triggerProfile, isSuccessProfile, isErrorProfile, messageProfile} = nextProps;
    const {userTrigger, userIsSuccess, userIsError, userMessage, isSuccessDivision, triggerDivision, divisionMessage, isErrorDivision, divisionDataByOrg} = nextProps;

    const {isLoadingWithdraw, isErrorWithdraw, isSuccessWithdraw, triggerWithdraw} = nextProps;
    const {isSuccessOrganization, triggerOrganization, orgStatusData, messageOrganization} = nextProps;
    const {isSuccessTerms, triggerTerms} = nextProps;
    const {isSuccessNotification, triggerNotification, notificationData} = nextProps;
    const {overtimeHistory, triggerAttendance} = nextProps;

    if (isSuccessWithdraw) {
      if (triggerWithdraw === WITHDRAW_STATE.UPDATE_SUCCESS || WITHDRAW_STATE.REJECT_SUCCESS) {
        this.fetchCheckStatus();
        // this.props.clearWithdraw();
      }
    }

    if (isSuccessNotification) {
      if (triggerNotification === NOTIFICATION_STATE.GET_SUCCESS) {
        this.setState({
          notif: notificationData,
        });
      }
    }

    if (triggerToken === REFRESH_TOKEN_STATE.UPDATE_REFRESH_TOKEN_SUCCESS) {
      const {isRefreshToken} = this.state;
      if (isRefreshToken) {
        localStorage.setItem('refreshToken', refreshToken?.refreshToken);
      }
    }

    if (isSuccessDivision) {
      if (triggerDivision === DIVISION_STATE.CREATE_SUCCESS) {
        if (this.props.location.pathname === '/user-management' || this.props.location.pathname === '/division-management') {
          return;
        } else if (this.props.location.pathname !== '/user-management' || this.props.location.pathname !== '/division-management') {
          this.props.getDivisionByOrg({
            limit: 100,
          });
          toast('success', divisionMessage);
          this.setState({
            modalDivisionShow: false,
            modalCreateEmploymentDivision: false,
            division_name: null,
            description: null,
            errorMessageDivision: null,
            createdDivision: true,
          });
        }
      }
      if (isSuccessTerms) {
        if (triggerTerms === ORGANIZATION_STATE.ACCEPT_TERMS_SUCCESS) {
          this.setState({buttonLoading: false});
          this.props.checkStatus();
        }
      }

      if (triggerDivision === DIVISION_STATE.FETCH_BY_ORG_SUCCCESS) {
        if (this.state.createdDivision) {
          let createdID = 0;
          for (let i = 0; i < divisionDataByOrg.length; i++) {
            if (divisionDataByOrg[i].id > createdID) {
              createdID = {id: divisionDataByOrg[i].id, name: divisionDataByOrg[i].division_name};
            }
          }
          this.setState({
            formDataProfile: {
              ...this.state.formDataProfile,
              division_id: createdID,
            },
            createdDivision: false,
            modalDivisionShow: false,
            modalCreateEmploymentDivision: false,
          });
        }
      }
    }

    if (isErrorDivision && divisionMessage) {
      if (isObject(divisionMessage)) this.setState({errorMessageDivision: divisionMessage});
      if (!isObject(divisionMessage)) toast('error', divisionMessage);
    }

    if (isErrorDivision && divisionMessage) {
      if (isObject(divisionMessage)) this.setState({errorMessageDivision: divisionMessage});
      if (!isObject(divisionMessage)) toast('error', divisionMessage);
    }

    if (userIsSuccess) {
      if (userTrigger === USER_STATE.FETCH_MANAGER_SUCCCESS) {
        this.fetchManager();
      }
      if (userTrigger === USER_STATE.UPDATE_SUCCESS) {
        this.props.getProfile(this.props.profile.user_id);
        this.props.clearState();
        this.fetchCheckStatus();
        this.setState({
          modalFormShowProfile: false,
          modalFormShow: false,
        });
        toast('success', message);

        if (this.props.location.pathname !== '/user-management') {
          this.fetchUser();
        }

        if (this.props.location.pathname === '/user-management') {
          this.props.history.push('/user-managements');
          setTimeout(() => {
            this.props.history.push('/user-management');
          }, 50);
        }
      }
    }


    if (triggerProfile === USER_STATE.FETCH_PROFILE_SUCCCESS) {
      this.setState({
        profileData: profileData,
        successFetchProfile: true,
      });
    }

    if (isSuccess && !isObject(message)) {
      if (
        trigger === STATE.CHECK_SUCCESS && !isSuperAdmin && this.props.location.state && this.props.location.state.prevURL === '/login' && !showMethod && !dueDate
      ) {
        this.setState({
          schedule: statusData.schedule ? true : false,
          showUpgradeModal: showUpgradeModal && false,
        }, () => {
          if (statusData.free_trial && !this.state.schedule) {
            this.toggleModalUpgrade(null, 'meeting');
          }
        },
        );
      }
    }

    const {isSuccessXendit, triggerXendit, xenditStatus} = nextProps;

    if (isSuccess && !isObject(message)) {
      if (
        trigger === STATE.CHECK_SUCCESS && this.state.orderId !== statusData.order_id
      ) {
        this.setState({
          orderId: statusData.order_id && statusData.order_id,
        }, () => this.props.checkXenditStatus({invoice_id: this.state.orderId}),
        );
      }
    }


    if (isSuccessXendit) {
      if (
        triggerXendit === XENDIT_STATE.CHECK_XENDIT_SUCCESS && this.state.invoiceUrl !== xenditStatus.invoice_url
      ) {
        this.setState({
          invoiceUrl: xenditStatus.invoice_url,
        });
      }
    }
    if (isError && message) {
      if (isObject(message)) this.setState({errorMessageProfile: message});
      if (!isObject(message)) {
        if (trigger === STATE.CHECK_FAILED) toast('error', message);
      }
    }

    if (triggerAttendance === 'ATTENDANCE_EXPORT_SUCCESS') {
      this.showExportNotif();
    }

    if (trigger === USER_STATE.EXPORT_SUCCESS) {
      this.showExportNotif();
    }

    if (triggerAttendance === 'ATTENDANCE_IMPORT_SUCCESS') {
      this.showImportNotif();
    }

    if (trigger === USER_STATE.IMPORT_SUCCESS) {
      this.showImportNotif();
    }

    if (triggerOrganization === ORGANIZATION_STATE.ASSIGN_HR_SUCCESS) {
      toast('success', messageOrganization);
      this.props.logout();
      this.props.clearRefreshToken();
      localStorage.removeItem('refreshToken');
      this.props.history.push('/login');
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getProfile(this.props.profile.user_id);
    const {token} = this.props;
    if (!token) this.props.history.push('/login');
    if (this.props.location.pathname !== '/user-management') {
      this.fetchUser();
    }
    this.props.getDivisionByOrg({
      limit: 100,
    });

    // set Global 401 / 500 Response handler
    apiService.interceptors.response.use(
      (response) => response,
      (error) => {
        const {response} = error;
        let errMsg = 'Something is wrong, please try again later';
        this.handleAuthError(error);

        if (error.response) {
          const errCode = error.response.status;
          console.log('interceptors-error-response : ', error.response);

          if (!response.data.data && errCode !== 403) {
            // * handle default error from server / axios (eg: timeout, 404/500 errors)
            if (process.env.REACT_APP_MODE === 'DEV') {
              errMsg = error.response.data.message ? error.response.data.data.message : error.response.statusText;
            }
            showPopup('error', errMsg);
          }
        } else {
          // * handle other global error (connection, api, etc)
          console.log('interceptors-xhr-error : ', error.toJSON());
          errMsg = (error.code === 'ECONNABORTED') ? 'Warning! There was a problem with your network connection' : errMsg;
          error = {response: {data: {meta: {code: 400}, data: {message: null}}}}; // imitate backend standard error response
          showPopup('error', errMsg);
        }
        return Promise.reject(error); // return all response as promise error
      },
    );
    const {getFcmToken} = this;

    async function fcmSupport() {
      const supported = await isSupported();
      if (supported) {
        getFcmToken();
      }
    }

    if (!this.props.isSuperAdmin) {
      fcmSupport();
    }
  }

  componentDidMount() {
    if (!this.props.isSuperAdmin) this.props.checkPremium();
    if (!this.props.isSuperAdmin) this.props.checkAmount();
    if (!this.props.isSuperAdmin) this.props.getInvoicePrice();
    if (!this.props.isSuperAdmin) this.props.getJobTitleByOrg();
    this.fetchCheckStatus();
    this.handleWindowResize();
    this.props.checkStatus();
    this.props.getNotification();
    window.addEventListener('resize', this.handleWindowResize);
    if (!this.props.isSuperAdmin) {
      const header = {
        'Content-Type': 'application/json',
        'Authorization': `${process.env.REACT_APP_API_BILLING_AUTHORIZATION}`,
      };
      axios.post(`${process.env.REACT_APP_API_URL_BILLING}/api/v1/products/active`, {email: this.props.profile?.email}, {
        headers: header,
      }).then((response) => {
        const datas = response?.data?.data;
        const billing_id = parseJwt(this.props.token)?.billing_id ?? null;
        const result = datas?.find((data) => data.billing_id === billing_id && (data?.product_active || data?.status_package === 'waiting_payment'));
        if (Object.keys(result).length > 0) {
          this.setState({package_available: result});
        } else {
          this.setState({package_available: null});
        }
      }).catch((error) => {
        console.log('error', error);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {userFeature} = this.state;
    const {statusData} = this.props;
    // if(prevProps.location.pathname !== this.props.location.pathname){
    //   this.fetchCheckStatus();
    //   if(!this.props.isSuperAdmin){
    //     if(isObject(userFeature)){

    //       if(userFeature.reimburse) {
    //         // this.props.history.push('/login');
    //       }
    //     }
    //   }
    // }


    if (!this.props.isSuperAdmin) {
      if (statusData.fossBilling === true) {
        if (prevProps.location.pathname ==='/invoice' || this.props.location.pathname === '/invoice') {
          this.props.history.push('/subscriptions-history');
        }
        if (prevProps.location.pathname ==='/payment-history' || this.props.location.pathname === '/payment-history') {
          this.props.history.push('/subscriptions');
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (statusData.fossBilling === false) {
        if (prevProps.location.pathname ==='/subscriptions-history' || this.props.location.pathname === '/subscriptions-history') {
          this.props.history.push('/invoice');
        }
        if (prevProps.location.pathname ==='/subscriptions' || this.props.location.pathname === '/subscriptions') {
          this.props.history.push('/payment-history');
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (isObject(this.props.userFeature)) {
        // dummy data
        // Object.assign(this.props.userFeature, { reimburse: !this.props.userFeature.reimburse });
        if (prevProps.location.pathname ==='/reimburse' || this.props.location.pathname === '/reimburse') {
          if (!this.props.userFeature.reimburse) {
            this.props.history.push('/login');
            toast('error', 'You don\'t have permission to access the page');
          }
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (isObject(this.props.userFeature)) {
        // dummy data
        // Object.assign(this.props.userFeature, { reimburse: !this.props.userFeature.reimburse });
        if (prevProps.location.pathname ==='/advantech-settings' || this.props.location.pathname === '/advantech-settings') {
          if (!this.props.userFeature.advantech) {
            this.props.history.push('/login');
            toast('error', 'You don\'t have permission to access the page');
          }
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (isObject(this.props.userFeature)) {
        // dummy data
        // Object.assign(this.props.userFeature, { reimburse: !this.props.userFeature.reimburse });
        if (prevProps.location.pathname ==='/payslip-settings' || this.props.location.pathname === '/payslip-settings') {
          if (!this.props.userFeature.payslip) {
            this.props.history.push('/login');
            toast('error', 'You don\'t have permission to access the page');
          }
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (isObject(this.props.userFeature)) {
        // dummy data
        // Object.assign(this.props.userFeature, { reimburse: !this.props.userFeature.reimburse });
        if (prevProps.location.pathname ==='/shift-management' || this.props.location.pathname === '/shift-management') {
          if (!this.props.userFeature.shift) {
            this.props.history.push('/login');
            toast('error', 'You don\'t have permission to access the page');
          }
        } else if (prevProps.location.pathname ==='/shift-setting' || this.props.location.pathname === '/shift-setting') {
          if (!this.props.userFeature.shift) {
            this.props.history.push('/login');
            toast('error', 'You don\'t have permission to access the page');
          }
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (isObject(this.props.userFeature)) {
        // dummy data
        // Object.assign(this.props.userFeature, { reimburse: !this.props.userFeature.reimburse });
        if (prevProps.location.pathname ==='/permit' || this.props.location.pathname === '/permit') {
          if (!this.props.userFeature.permit) {
            this.props.history.push('/login');
            toast('error', 'You don\'t have permission to access the page');
          }
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (isObject(this.props.userFeature)) {
        // dummy data
        // Object.assign(this.props.userFeature, { reimburse: !this.props.userFeature.reimburse });
        if (prevProps.location.pathname ==='/approval-order-setting' || this.props.location.pathname === '/approval-order-setting') {
          if (!this.props.userFeature.approval) {
            this.props.history.push('/dashboard');
            toast('error', 'You don\'t have permission to access the page');
          }
        }
      }
    }

    if (!this.props.isSuperAdmin) {
      if (isObject(this.props.userFeature)) {
        // dummy data
        // Object.assign(this.props.userFeature, { reimburse: !this.props.userFeature.reimburse });
        if (prevProps.location.pathname ==='/leave-config' || this.props.location.pathname === '/leave-config') {
          if (!this.props.userFeature.paidleave) {
            this.props.history.push('/dashboard');
            toast('error', 'You don\'t have permission to access the page');
          }
        } else if (prevProps.location.pathname ==='/leave-history' || this.props.location.pathname === '/leave-history') {
          if (!this.props.userFeature.paidleave) {
            this.props.history.push('/dashboard');
            toast('error', 'You don\'t have permission to access the page');
          }
        } else if (prevProps.location.pathname ==='/leave-management' || this.props.location.pathname === '/leave-management') {
          if (!this.props.userFeature.paidleave) {
            this.props.history.push('/dashboard');
            toast('error', 'You don\'t have permission to access the page');
          }
        } else if (prevProps.location.pathname ==='/office-inventory' || this.props.location.pathname === '/office-inventory') {
          if (!this.props.userFeature.office_inventory) {
            this.props.history.push('/dashboard');
            toast('error', 'You don\'t have permission to access the page');
          }
        } else if (prevProps.location.pathname ==='/master-inventory' || this.props.location.pathname === '/master-inventory') {
          if (!this.props.userFeature.office_inventory) {
            this.props.history.push('/dashboard');
            toast('error', 'You don\'t have permission to access the page');
          }
        } else if (prevProps.location.pathname ==='/cash-advance' || this.props.location.pathname === '/cash-advance') {
          if (!this.props.userFeature.cash_advance) {
            this.props.history.push('/dashboard');
            toast('error', 'You don\'t have permission to access the page');
          }
        }
      }

      if (prevProps.location.pathname ==='/event-management' || this.props.location.pathname === '/event-management') {
        if (!userFeature?.pagiiEvent) {
          this.props.history.push('/dashboard');
          toast('error', 'You don\'t have permission to access the page');
        }
      }
    }
  }

  getFcmToken = () => {
    const messaging = getMessaging();
    return getToken(messaging, {vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY})
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem('fcmToken', currentToken);

          try {
            apiService.post(ENDPOINTS.USER.UPDATE_FCM_TOKEN, {
              fcmtoken: currentToken,
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  fetchCheckStatus = () => {
    apiService.get(`/user/check-status`)
        .then((res) => this.setState({userFeature: res.data.data}))
        .catch(() => null);
  };

  menusFilter = () => {
    const {role, menusData} = this.props;
    const filtering = role ? menus(role, organization?.id ?? null).filter((val) => val.name ? menusData ? menusData[val.name] : null : true ) : null;
    this.setState({menusList: filtering});
  }

  fetchUser = () => {
    const {isSuperAdmin} = this.props;
    const {page, limit, query, sortBy, order, type} = this.state;

    if (isSuperAdmin) {
      this.props.getNewUsers({page, limit, query, sort_by: sortBy, order, type: 'guest'});
    } else {
      // this.props.getAllUser({page, limit, query, sort_by: sortBy, order});
      this.props.getNewUsers({page, limit, query, sort_by: sortBy, order});
    }
  }

  handleWindowResize = () => {
    const width = window.innerWidth < 1024;
    this.setState({isDrawerOpen: width ? false : true});
  };

  handleLogout = () => {
    localStorage.removeItem('refreshToken');

    if (this.props.isSuperAdmin) {
      this.props.logout();
      this.props.clearRefreshToken();
      this.props.history.push('/sysadm-login');
    } else {
      this.props.logout();
      this.props.clearRefreshToken();
      this.props.history.push('/login');
    }
  }

  handleNavigation = (data) => {
    const {statusData} = this.props;

    if (statusData.fossBilling) {
      if (data.title === 'Invoice') {
        this.setState({title: 'Subscription'});
        this.props.history.push('/subscriptions');
        this.props.getNotification();
      } else if (data.title === 'History') {
        this.setState({title: 'Subscription History'});
        this.props.history.push('/subscriptions-history');
        this.props.getNotification();
      } else {
        this.setState({title: data.title});
        this.props.history.push(data.navigation);
        this.props.getNotification();
      }
    } else {
      this.setState({title: data.title});
      this.props.history.push(data.navigation);
      this.props.getNotification();
    }
  };

  togglePopupLogout = () => {
    this.setState({isMenuOpen: !this.state.isMenuOpen});
  }

  handleEditProfile = async (data) => {
    const {profileData} = this.state;
    let manager;
    if (data.manager_id !== null) {
      manager = await this.props.getOneManager(this.props.profile.user_id * 1);
    }
    const role_id_data = this.props.profile.job_title_id;
    // const manager = {
    //   nik: profileData?.manager_id,
    //   name: profileData?.manager_name,
    // };
    // console.log(this.props.profile);
    if (this.props.location.pathname !== '/user-management') {
      this.setState({
        isEdit: true,
        modalFormShowProfile: !this.state.modalFormShowProfile,
        formDataProfile: {
          id: profileData?.id,
          name: profileData?.name,
          nik: profileData?.nik,
          email: profileData?.email,
          address: profileData?.address,
          role_id: this.props.profile?.role_id,
          job_title_id: role_id_data,
          manager: manager,
          join_date: profileData?.join_date,
          dob: profileData?.dob ? convDate(profileData?.dob) : null,
          phone: profileData?.phone,
          imageURL: profileData?.image,
          division_id: {id: profileData?.division_id, name: profileData?.division_name},
        },
      });
      this.handleCloseAccMenu();
    } else {
      this.setState({modalFormShowProfile: false});
    }
  }

  toggleDrawer = () => {
    this.setState({isDrawerOpen: !this.state.isDrawerOpen});
  }

  handleInputChangeDivision = (event) => {
    const {formDataProfile} = this.state;
    const {name, value} = event.target;
    if (value === 0) {
      this.setState({modalDivisionShow: true});
    } else {
      this.setState({
        formDataProfile: {
          ...formDataProfile,
          [name]: value,
        },
      });
    }
  }

  handleDivisionToggle = () => {
    this.setState({
      modalDivisionShow: !this.state.modalDivisionShow,
      division_name: null,
      description: null,
      errorMessageDivision: null,
    });
  };

  renderModalCreateDivision = () => {
    const {
      classes,
      isLoadingDivision,
    } = this.props;
    const {
      modalDivisionShow,
      errorMessageDivision,
      division_name,
      description,
    } = this.state;
    const titleDialog = 'Create Division';

    return (
      <Dialog
        open={modalDivisionShow}
        onClose={() => this.handleDivisionToggle()}
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            className={classes.modalTitle}
            title={titleDialog}
            onClose={() => this.handleDivisionToggle()}
          />
        </MuiThemeProvider>
        <form onSubmit={this.handleSubmitDivision} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <MInput
              classNameFC={classes.formControl}
              name="division_name"
              label="Division Name"
              placeholder="Enter division name"
              defaultValue={division_name}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageDivision && errorMessageDivision.division_name ?
                  errorMessageDivision.division_name :
                  undefined
              }
              autoFocus
            />
            <MInput
              classNameFC={classes.formControl}
              name="description"
              label="Description"
              placeholder="Enter description"
              defaultValue={description}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageDivision && errorMessageDivision.description ?
                  errorMessageDivision.description :
                  undefined
              }
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleDivisionToggle()}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={this.state.buttonLoading}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  toggleModalUpgrade = (status, method) => {
    const {isPremium, expiredDate, isPaid, dueDate} = this.props;
    // if (!status) status = !this.state.showUpgradeModal;
    // if (!method) method = null;
    // if ((isPremium && (getTimeDiff(expiredDate)+1) >= INVOICE_PERIOD && method !== 'meeting') || (isPaid && method !== 'meeting')) status = false;
    // // if ((!method && !isPremium && (getTimeDiff(dueDate)+1) < 0) || (!method && !dueDate)) method = 'plan';
    // // if (!method && !isPremium && (getTimeDiff(dueDate)+1) >= 0) method = 'xendit';
    // // if (!method && isPremium && (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD) method = 'xendit';
    // if ((!isPremium && (getTimeDiff(dueDate)+1) <= 0) || (!isPremium && !dueDate)) method = 'plan';
    // if ((!isPremium && (getTimeDiff(dueDate)+1) > 0 && isPaid !== true) || (isPremium && (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD && !isPaid)) method = 'xendit';


    this.setState({showUpgradeModal: status, showMethod: method});
  }

  toggleModalCancel = (status) => {
    if (!status) status = !this.state.showCancelModal;
    this.setState({showCancelModal: status});
  }

  toggleAlert = (status) => {
    if (!status) status = !this.state.showAlert;
    this.setState({showAlert: status});
  }

  renderLoader = (size = 15) => {
    const {classes} = this.props;
    return <CircularProgress className={classes.loaderColor} size={size} />;
  };

  renderNotif = () => {
    const {notifModal, notif} = this.state;
    const {isLoadingUser, classes} = this.props;
    const {loadingRead, loadingIndex} = this.state;

    const newNotif = [];
    notif.map((value, index) => {
      newNotif.push(value);
    });

    return (
      <>
        <Zoom in={notifModal} >
          <Paper style={{
            'position': 'absolute',
            'top': '50px',
            'right': '0px',
            'minWidth': '350px',
            'maxHeight': '500px',
            'padding': 10,
            'overflow': 'auto',
          }}>
            {isLoadingUser || !newNotif ? (
              this.renderLoader()
              ) : (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                  >
                    {'Notifications'}
                  </Typography>
                  <List component="nav" aria-label="secondary mailbox folders">
                    {
                      notif && notif.length > 0 ? notif.map((value, index) => {
                        return (
                          <div key={index}>
                              <div key={index} style={{backgroundColor: value.is_read ? '#fff' : '#ffeebf', borderRadius: '14px', margin: '5px 0px'}}>
                              <ListItem divider={true}>
                                <ListItemText
                                  className={classes.menuTitle}
                                  primary={value.subject}
                                  secondary={
                                    <React.Fragment>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                      >
                                        {moment(value.created_at).format('DD/MM/YY')}
                                      </Typography>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                      >
                                        {' - ' + value.description + ' '}
                                      </Typography>
                                      {
                                        value.openable && loadingRead && value.id === loadingIndex ? (
                                          <CircularProgress style={{width: 15, height: 15}}/>
                                        ) : (
                                        <Link onClick={() => {
                                          this.setState({
                                            loadingRead: true,
                                            loadingIndex: value.id,
                                          });
                                          apiService.put(`/announcement/read/${value.id}`, {is_read: true})
                                            .then((res) => {
                                              this.setState({
                                                notifModal: false,
                                                loadingRead: false,
                                                loadingIndex: null,
                                              });
                                              this.props.history.push(`/${value.feature === 'paidleave' ? `leave-management?query=${value.description.split(' ')[0]}` :
                                                value.feature === 'overtime' ? `overtime-history?query=${value.description.split(' ')[0]}` :
                                                  value.feature === 'reminder-contract' ? `employee-detail?id=${value.feature_id ? value.feature_id : null}` : value.feature + `?query=${value.description.split(' ')[0]}`}`);
                                              this.props.getNotification();
                                            })
                                            .catch(() => null);
                                        }} href={'#'} rel="noopener noreferrer">
                                          <Typography
                                            component="span"git reset

                                            variant="body2"
                                            className={classes.inline}
                                          >
                                            {'[See Details]'}
                                          </Typography>
                                        </Link>
                                        )
                                      }
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            </div>
                          </div>
                        );
                      }) :
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          textalign='center'
                        >
                          {'There is no notification'}
                        </Typography>
                    }
                  </List>
                </>
            )}
          </Paper>
        </Zoom>
      </>
    );
  }

  handleIconPackage = (status) => {
    const {classes} = this.props;
    if (status === 'paid') {
      return <Done className={classes.chipIcon}/>;
    } else {
      return <Star className={classes.chipIcon}/>;
    }
  }

  handleClassNamePackage = (status) => {
    const {classes} = this.props;
    let result;
    switch (status) {
      case 'paid':
        result = classes.chipPremium;
        break;
      case 'waiting_payment':
        result = classes.chipWaitingPayment;
        break;
      case 'expired':
        result = classes.chipFree;
        break;
      default:
        result = classes.chipFree;
        break;
    }
    return result;
  }

  handleLabelPackage = (status) => {
    let result;
    switch (status) {
      case 'paid':
        result = 'Premium';
        break;
      case 'waiting_payment':
        result = 'Waiting for Payment';
        break;
      case 'expired':
        result = 'Upgrade to Premium';
        break;
      default:
        result = 'Upgrade to Premium';
        break;
    }
    return result;
  }

  renderUpgrade = () => {
    const {isSuperAdmin, classes, isPremium, isPaid, loadingPremium, statusData, paymentMethod, expiredDate, dueDate, isTrial, orgStatusData} = this.props;
    document.body.style.overflow = 'auto';
    if (isSuperAdmin) return null; // only render for non superadmin
    if (loadingPremium) return <Chip className={classes.chipLoading} label="Loading" size="small"/>;
    // return (
    //   <Chip
    //     onClick={() => this.props.history.push('/subscriptions')}
    //     icon={this.handleIconPackage(orgStatusData?.subscription_status)}
    //     className={this.handleClassNamePackage(orgStatusData?.subscription_status)}
    //     label={this.handleLabelPackage(orgStatusData?.subscription_status)}
    //     size="small"
    //   />
    // );


    document.body.style.overflow = 'auto';
    return (
      <>
      {statusData.fossBilling === true ? (
        <Chip
          onClick={() => this.props.history.push('/subscriptions')}
          icon={this.handleIconPackage(orgStatusData?.subscription_status)}
          className={this.handleClassNamePackage(orgStatusData?.subscription_status)}
          label={this.handleLabelPackage(orgStatusData?.subscription_status)}
          size="small"
        />
      ) : (
        <Chip
          icon={isPremium ? <Done className={classes.chipIcon}/> : <Star className={classes.chipIcon}/>}
          size="small"
          className={
            isTrial && !dueDate?
            classes.chipPremium :
              (!isPremium && (getTimeDiff(dueDate)+1) <= 0) || (!isPremium && !dueDate)?
              classes.chipFree :
              !isPremium && (getTimeDiff(dueDate)+1) > 0 && isPaid !== true ?
                classes.chipWaitingPayment : !isPremium && isPaid === true ?
                classes.chipWaitingApproval : (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD ?
                classes.chipPremiumBankPayment :
                classes.chipPremiumBank
          }
          label={
            isTrial && !dueDate?
            'Free Account' :
              (!isPremium && (getTimeDiff(dueDate)+1) <= 0) || (!isPremium && !dueDate) ?
              'Upgrade to Premium' :
                (!isPremium && (getTimeDiff(dueDate)+1) > 0 && isPaid !== true) || (isPremium && (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD && !isPaid) ?
                  'Waiting for Payment' : !isPremium && isPaid === true ?
                    'Waiting for Approval' :
                      'Premium'
          }
          clickable={!isPremium && !isPaid ? true : (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD && !isPaid ? true : (!isPremium && !dueDate) ? true : (!isPremium && (getTimeDiff(dueDate)+1) <= 0) ? true : false}
          onClick={
            orgStatusData.discount_status === 'inactive' ? () => showPopup('info', 'You still have an invitational from partner, please respond to the invitation first') :
            isTrial && !dueDate ? () => this.toggleModalUpgrade(true, 'plan') :
            (!isPremium && (getTimeDiff(dueDate)+1) <= 0) || (!isPremium && !dueDate) ? () => this.toggleModalUpgrade(true, 'plan') :
            (!isPremium && (getTimeDiff(dueDate)+1) > 0 && isPaid !== true) || (isPremium && (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD && !isPaid) ? () => this.toggleModalUpgrade(true, 'xendit') :
            null
          }
        />
      )}
      </>
    );
  }

  renderCopyRightMain = () => {
    const {classes} = this.props;
    return (
      <footer className={classes.copyright}>
        {/* Copyright 2020 &copy; &nbsp; */}
        {/* <a className={classes.link} href={'https://smooets.com'}>PT Smooets Teknologi Outsourcing</a> */}
        Copyright 2020 ©  PT Smooets Teknologi Outsourcing
      </footer>
    );
  }

  renderAlert = () => {
    const {classes, isPremium, isSuperAdmin, expiredMessage, price} = this.props;
    const defaultMessage = `You are using free version, upgrade to premium with ${price} to have unlimited access & employees`;
    const message = expiredMessage ? expiredMessage : defaultMessage;
    if ((isPremium || isSuperAdmin) && !expiredMessage) return null; // only render for non superadmin & free member or expired

    return (
      <div className={classes.alert}>
        <div className={classes.alertText}>
          <InfoOutlined className={classes.alertIcon}/>
          <span
            className={classes.alertLink}
            onClick={this.toggleModalUpgrade}
          >
            {message}
          </span>
        </div>
        <IconButton
          className={classes.alertClose}
          aria-label="close"
          color="secondary"
          size="small"
          onClick={() => this.toggleAlert(false)}
        >
          <Close fontSize="inherit" />
        </IconButton>
      </div>
    );
  }

  renderLogoutPopup = () => {
    const {classes} = this.props;
    const {isMenuOpen} = this.state;
    return (
      <Dialog
        open={isMenuOpen}
        onClose={this.togglePopupLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent className={classes.logoutContent} dividers>
          <Typography> Are you sure want to logout? </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelBtn}
            style={{boxShadow: 'none'}}
            variant="contained"
            size='small'
            onClick={this.togglePopupLogout}
          >
            Cancel
          </Button>
          <Button
            className={classes.logoutBtn}
            style={{boxShadow: 'none'}}
            variant="contained"
            size='small'
            onClick={() => this.handleLogout()}
            startIcon={<ExitToApp />}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  isEmpty = (obj) => {
    return Object.values(obj).every((x) => x === null || x === '');
  }

  renderDrawer = () => {
    const {classes, role, organization, attendanceTypes, menusData, userFeature, isSuperAdmin, statusData} = this.props;

    const {isDrawerOpen, menusList} = this.state;
    const currentURL = this.props.location.pathname;
    const specialOverflow = [
      '/invoice',
      '/announcements',
      '/job-titles',
      '/working-time',
      '/leaderboard-settings',
      '/advantech-settings',
      '/generate',
      '/permit',
      '/leave-config',
      '/leave-history',
      '/leave-management',
      '/shift-management',
    ].includes(window.location.pathname);

    const filtering = role ? menus(role, organization?.id ?? null)
      .filter((val) => val.name === 'pagiiEvent' && isSuperAdmin ? true : val.name ? menusData ? menusData[val.name] : null : true) : null;

    const styleContainer = isSuperAdmin ? {} : {height: `${specialOverflow ? `110vh` : `auto`}`, overflow: 'auto'};
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Drawer
          variant="permanent"
          classes={{paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose)}}
          onMouseEnter={isDrawerOpen ? null : this.toggleDrawer}
          open={isDrawerOpen}
          style={styleContainer}
        >
          <div className={clsx(classes.headerDrawerOpen, !isDrawerOpen && classes.headerDrawerClose)}>
            <Grid container>
              <Grid item xs={9}>
                {
                  organization && !this.isEmpty(organization) ?
                  (
                    <div className={classes.toolbarIcon}>
                      {isDrawerOpen ? (
                      <CardMedia title={organization ? organization.name : 'Company Logo'}>
                        {organization.logo ? <img className={classes.logoCompany} alt="img" src={organization.logo}/> : null}
                      </CardMedia>
                      ) : (
                      <iconButton className={classes.logoBtn} onMouseOver={this.toggleDrawer} onMouseOut={this.toggleDrawer}>
                        <CardMedia title={organization ? organization.name : 'Company Logo'}>
                          {organization.logo ? <img className={classes.logoCompanyShift} alt="img" src={organization.logo}/> : null}
                        </CardMedia>
                      </iconButton>
                      )}
                      {isDrawerOpen ? (
                        <h3 className={classes.companyName}>{organization ? organization.name : 'Pagii'}</h3>
                      ): (
                        <h3 className={classes.companyName}>{organization ? organization.name : 'Pagii'}</h3>
                      )}
                    </div>
                  ) :
                  (
                    <div className={classes.toolbarIcon}>
                      {isDrawerOpen ? (
                      <CardMedia title={organization ? organization.name : 'Company Logo'}>
                        <img className={classes.logoPagii} alt="img" src={pagiiLogo}/>
                      </CardMedia>
                      ) : (
                      <CardMedia title={organization ? organization.name : 'Company Logo'}>
                        <img className={classes.logoPagiiShift} alt="img" src={pagiiLogoShort}/>
                      </CardMedia>
                      )}
                    </div>
                  )
                }
              </Grid>
              <Grid item xs={3}>
                {isDrawerOpen ? (
                  <IconButton className={classes.drawerButton} onClick={this.toggleDrawer}>
                    <ChevronLeft />
                  </IconButton>
                ) : (
                <IconButton className={classes.drawerButtonShift} onClick={this.toggleDrawer}>
                  <ChevronLeft />
                </IconButton>
                )
                }
              </Grid>
            </Grid>
          </div>
          <List style={{marginTop: 68}}>
            { role ? filtering.filter((item) => {
              return isSuperAdmin ? item : this.state.userFeature && this.state.userFeature.tenant_management ? item : (
              item.title !== 'Company Management'
            );
            })
                .filter((item) => {
                  return organization.is_child ? item.title !== 'Payment' : item;
                }).map((item, index) => {
                  if (item.expandable) {
                    // Primary Expandable
                    const active = currentURL === item.navigation ? true : false;
                    return <div key={index }>
                      {isDrawerOpen ?
                (
                  <ListItem
                    button
                    key={index }
                    // className={classes.listActive}
                    onClick={() => this.setState({[item.refKey]: !this.state[item.refKey]})}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {item.title === 'Account Representative Management' && this.state.userFeature?.withdrawal !== 0 ? (
                      <ListItemText primaryTypographyProps={{style: {whiteSpace: 'normal'}}} primary={isDrawerOpen ? item.title : ` (${this.state.userFeature?.withdrawal})`} className={classes.menuTitle}/>
                      ) : (
                      <ListItemText primaryTypographyProps={{style: {whiteSpace: 'normal'}}} primary={isDrawerOpen ? item.title : ''} className={classes.menuTitle}/>
                    )}
                    { this.state[item.refKey] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  ):
                (
                  <ListItem
                    // className={active ? classes.listActive : ''}
                    button
                    key={index }
                    // className={classes.listActive}
                    onClick={() => this.setState({[item.refKey]: !this.state[item.refKey]})}
                  >
                    <ListItemIcon style={{paddingLeft: 6}}>{item.icon}</ListItemIcon>
                    <ListItemText primary={isDrawerOpen ? item.title : ''} className={classes.menuTitle}/>
                    { this.state[item.refKey] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  )
                      }

                      {isDrawerOpen ? (
                  <Collapse
                    in={this.state[item.refKey]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {
                        item.children.map((child, index) => {
                          const active = currentURL === child.navigation ? true : false;
                          if (child.expandable) {
                            return <div key={index }>
                              <ListItem
                                button
                                key={index }
                                className={classes.nested}
                                onClick={() => this.setState({[child.refKey]: !this.state[child.refKey]})}
                              >
                                <ListItemText primary={child.title} className={classes.menuTitle} />
                                {this.state[child.refKey] ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse
                                in={this.state[child.refKey]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {
                                    child.children.map((child, index) => {
                                      const active = currentURL === child.navigation ? true : false;
                                      if (menusData && (menusData[child.name] === false || menusData[child.name] === null)) {
                                        return null;
                                      } else {
                                        return (
                                          <ListItem
                                            key={index }
                                            button
                                            className={active ? classes.nestedActive : classes.nested}
                                            onClick={() => this.handleNavigation(child)}
                                          >
                                            {/* <ListItemText primary={`${child.title} ${child.title === 'Overtime History' ? this.props.orgStatusData && this.props.orgStatusData.overtime ? '('+this.props.orgStatusData.overtime+')' : '' : '' } `} /> */}
                                            <ListItemText className={classes.menuTitle} primary={`${isDrawerOpen ? child.title : ''} ${this.setBadgeCounter(child, this.props.orgStatusData)}`} style={{paddingLeft: 20}} />
                                          </ListItem>
                                        );
                                      }
                                    })
                                  }
                                </List>
                              </Collapse>
                            </div>;
                          } else {
                            if (attendanceTypes.overtime === false && child.title === 'Overtime History ') {
                              return null;
                            } else if (child.title === 'Old Invoice' && statusData.fossBilling === false) {
                              return null;
                            } else {
                              return (
                                // Parent expanded child'
                                <>
                                  {child.title === 'Office Inventory' && menusData.office_inventory === false ? null :
                                    child.title === 'Master Inventory' && menusData.office_inventory === false ? null :
                                      child.title === 'Cash Advance' && menusData.cash_advance === false ? null :
                                      (
                                    <ListItem
                                      key={index }
                                      button
                                      className={active ? classes.nestedActive : classes.nested}
                                      onClick={() => this.handleNavigation(child)}
                                    >
                                      {/* <ListItemText primary={`${child.title} ${child.title === 'Overtime History' ? this.props.orgStatusData && this.props.orgStatusData.overtime ? '('+this.props.orgStatusData.overtime+')' : '' : '' } `} /> */}
                                      {!isSuperAdmin ? (
                                      statusData.fossBilling && child.title === 'Invoice' ? (
                                        <ListItemText className={classes.menuTitle} primary={`${isDrawerOpen ? 'Subscription' : ''} ${this.setBadgeCounter(child, this.props.orgStatusData)}`} />
                                      ) :
                                      statusData.fossBilling && child.title === 'History' ? (
                                        <ListItemText className={classes.menuTitle} primary={`${isDrawerOpen ? 'Subscription History' : ''} ${this.setBadgeCounter(child, this.props.orgStatusData)}`} />
                                      ) :
                                    <ListItemText className={classes.menuTitle} primary={`${isDrawerOpen ? child.title : ''} ${this.setBadgeCounter(child, this.props.orgStatusData)}`} />
                                  ) : isDrawerOpen ? child.title : '' === 'Withdraw Request' && this.state.userFeature?.withdrawal !== 0 ? (
                                    <ListItemText className={classes.menuTitle} primary={`${isDrawerOpen ? child.title : ''} (${this.state.userFeature?.withdrawal})`} />
                                  ) : isDrawerOpen ? child.title : '' === 'Withdraw Request' && this.state.userFeature?.withdrawal === 0 || this.state.userFeature?.withdrawal === null ?(
                                    <ListItemText className={classes.menuTitle} primary={`${isDrawerOpen ? child.title : ''}`} />
                                    ) : (
                                        <ListItemText className={classes.menuTitle} primary={`${isDrawerOpen ? child.title + 'asdasd' : ''} ${this.setBadgeCounter(child, this.props.orgStatusData)}`} />
                                      )}
                                    </ListItem>
                                  )}
                                </>
                              );
                            }
                          }
                        })
                      }
                    </List>
                  </Collapse>
                ) : (
                    null
                  )
                      }

                    </div>;
                  } else {
                    // Non-Expand Child Menu
                    const active = currentURL === item.navigation ? true : false;
                    const titleItem = this.setTitleItem(item);
                    return (
                      <ListItem
                        key={index }
                        className={active ? classes.listActive : ''}
                        onClick={() => this.handleNavigation(item)}
                        button
                      >
                        {isDrawerOpen ? (
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    ) : (
                    <ListItemIcon style={{paddingLeft: 6}}>{item.icon}</ListItemIcon>
                  )}
                        <ListItemText
                          primary={isDrawerOpen ? titleItem : ''}
                          primaryTypographyProps={{style: {whiteSpace: 'normal'}}}
                        />
                      </ListItem>
                    );
                  }
                }) : null}
          </List>
        </Drawer>
      </MuiThemeProvider>
    );
  }

  handleMeetingLink = () => {
    window.open(MEETING_LINK);
  }

  searchCombo = (event = null, value) => {
    this.handleDebounceSearch(event.target.value);
  }

  handleAccMenu = (event) => {
    if (this.state.profileData === null) {
      this.props.getProfile(this.props.profile.user_id);
    }
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleCloseAccMenu = () => {
    this.setState({
      anchorEl: null,
    });
  };

  notifToggle = () => {
    const {notif} = this.state;
    const notifCount = notif && notif.filter((value) => {
      return value.read === false;
    });

    this.setState({
      notifModal: !this.state.notifModal,
    }, () => {
      if (!this.state.notifModal && notifCount.length > 0) this.props.readNotifUser();
    });
  }

  renderAppBar = () => {
    const {classes, profile, organization, isTrial, statusData, isSuperAdmin} = this.props;
    const {isDrawerOpen, anchorEl, notifModal, notif, userFeature} = this.state;
    const queryParam = this.props.location.search.substring(1);
    const searchParam = new URLSearchParams(queryParam);
    const company = searchParam.get('company');

    const getMenuTitle = () => {
      const path = this.props.location.pathname;
      const pathArray = path.split('/');
      const currentPath = isArray(pathArray) ? pathArray[1] : path;
      const menuTitle = currentPath.replace(/^\/|\/$/g, '').replace(/-|\s/g, ' ');
      if (this.props.location.pathname === '/user-managements') {
        return 'User Management';
      } else if (this.props.location.pathname === '/sso-management') {
        return 'SSO Management';
      } else if (this.props.location.pathname === '/employee-facilities-setting') {
        return 'Employee Facilities';
      } else if (this.props.location.pathname === '/employee-detail') {
        return 'User Management';
      } else if (this.props.location.pathname === '/employees-list') {
        return `Employee List - ${company}`;
      } else {
        return menuTitle;
      }
    };
    const menuTitle = getMenuTitle();
    const open = Boolean(anchorEl);
    const notifCount = notif && notif.filter((value) => {
      return value.read === false;
    });

    const newNotif = [];
    notif.map((value, index) => {
      if (value.is_read === false) {
        newNotif.push(value);
      }
    });

    return (
      <AppBar position="absolute" className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={this.toggleDrawer}
            className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
          >
            <MenuIcon className={classes.menuShift}/>
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={isDrawerOpen ? classes.title : classes.titleShift}>
            {menuTitle ? menuTitle : ''}
          </Typography>
          {
            !isSuperAdmin &&
            <span style={{marginRight: '28px'}}>Total Active Users: {statusData && statusData.total_user ? statusData.total_user : 0} / {statusData && statusData.user_limit ? statusData.user_limit : 0}</span>
          }
          { isSuperAdmin ? this.renderUpgrade() : organization.is_child ? (
            <Chip
              size='small'
              className={classes.chipPremiumBank}
              label={`Member of ${userFeature?.parent_name ? userFeature.parent_name : '-'}`}
            />
          ) : this.renderUpgrade()}
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ClickAwayListener onClickAway={() => {
              notifModal && this.notifToggle();
            }}>
              <Box style={{'position': 'relative'}}>
                <Badge badgeContent={newNotif.length} color="secondary" onClick={() => this.notifToggle()} style={{'cursor': 'pointer', 'marginRight': 12}}>
                  <Notifications />
                </Badge>
                {this.renderNotif()}
              </Box>
            </ClickAwayListener>
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={this.handleAccMenu}
              style={{minWidth: 0, padding: 'auto 0px', textTransform: 'none', fontWeight: '400'}}
            >
              { this.state.profileData?.image ?
              <div className={classes.avatarMini}>
                <img
                  src={this.state.profileData?.image}
                  className={classes.avatarMiniImg}
                  alt="avatar"
                />
              </div> :
              <div className={classes.avatarMini}>
                <img
                  src={photoDefault}
                  style={{
                    display: 'inline',
                    margin: '0 auto',
                    height: 'auto',
                    width: '100%',
                  }}
                  alt="avatar"
                />
              </div>
              }
              <span style={{color: '#fff'}}>{this.state.profileData?.name}</span>
              <ExpandMore style={{color: '#fff'}}/>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              open={open}
              onClose={this.handleCloseAccMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              transformOrigin={{vertical: 'top', horizontal: 'center'}}
            > {this.state.profileData !== null && !isSuperAdmin ? <MenuItem onClick={() => this.handleEditProfile(this.state.profileData)}>Your Profile</MenuItem> : (null)}
              {isTrial && <MenuItem onClick={this.handleMeetingLink}>Schedule Meeting</MenuItem>}
              <MenuItem onClick={this.togglePopupLogout}>Logout</MenuItem>
            </Menu>
            {/* <IconButton onClick={this.togglePopupLogout} color="inherit">
              <ExitToAppRounded />
            </IconButton> */}
            { this.renderLogoutPopup() }
          </div>
        </Toolbar>
        {impersonateHrMode(this.state?.profileData?.id) && (
          <div style={{backgroundColor: '#e53934', padding: 3, display: 'flex', justifyContent: 'center'}}>
            <Typography>
              You are logging in as HR Mode. Please don’t forget to logout
            </Typography>
          </div>
        )}
      </AppBar>
    );
  }

  setTitleItem = (item) => {
    let setTitle;
    if (item.title === 'Paid Leave') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.paidleave ? '('+this.props.orgStatusData.paidleave+')' : ''}`;
    } else if (item.title === 'Permit') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.permit ? '('+this.props.orgStatusData.permit+')' : ''}`;
    } else if (item.title === 'Overtime History') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.overtime ? '('+this.props.orgStatusData.overtime+')' : ''}`;
    } else if (item.title === 'Withdraw Request') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.withdrawal ? '('+this.props.orgStatusData.withdrawal+')' : ''}`;
    } else if (item.title === 'Leave Management') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.paidLeave ? '('+this.props.orgStatusData.paidLeave+')' : ''}`;
    } else if (item.title === 'Office Inventory') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.office_inventory ? '('+this.props.orgStatusData.office_inventory+')' : ''}`;
    } else if (item.title === 'Cash Advance') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.cash_advance ? '('+this.props.orgStatusData.cash_advance+')' : ''}`;
    } else if (item.title === 'Master Inventory') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.master_inventory ? '('+this.props.orgStatusData.master_inventory+')' : ''}`;
    } else if (item.title === 'Reimburse') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.reimburse ? '('+this.props.orgStatusData.reimburse+')' : ''}`;
    } else if (item.title === 'Fraud Detection') {
      setTitle = `${item.title} ${this.props.orgStatusData && this.props.orgStatusData.frauddetection ? '('+this.props.orgStatusData.frauddetection+')' : ''}`;
    } else {
      setTitle = `${item.title}`;
    }

    return setTitle;
  }

  setBadgeCounter = (child, orgStatusData) => {
    if (child.title === 'Overtime History') {
      return orgStatusData && orgStatusData.overtime ? '('+orgStatusData.overtime+')' : '';
    } else if (child.title === 'Leave Management') {
      return orgStatusData && orgStatusData.paidleave ? '('+orgStatusData.paidleave+')' : '';
    } else if (child.title === 'Office Inventory') {
      return orgStatusData && orgStatusData.office_inventory ? '('+orgStatusData.office_inventory+')' : '';
    } else if (child.title === 'Cash Advance') {
      return orgStatusData && orgStatusData.cash_advance ? '('+orgStatusData.cash_advance+')' : '';
    } else if (child.title === 'Withdraw Request') {
      return orgStatusData && orgStatusData.withdrawal ? '('+orgStatusData.withdrawal+')' : '';
    } else {
      return '';
    }
  }

  toggleCropperModal = (status) => {
    this.setState({isCroppingProfile: status});
  };

  handleSubmitAgreement = (e) => {
    e.preventDefault();
    this.setState({buttonLoading: true});
    this.props.acceptTermsAndCondition({is_accept: true});
  }
  handleCheckboxTNC = (event) => {
    const {errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState({
      errorMessage: {...errorMessage, [name]: null},
      [name]: checked,
    });
  }

  handleCrop = (result) => {
    this.setState({
      formDataProfile: {
        ...this.state.formDataProfile,
        user_img: result.blob,
        cropped_user_img: result.blob,
        imageURL: result.imageURL,
      },
    });
  };

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    const bottom2 = e.target.scrollHeight - e.target.scrollTop < 600;

    if (bottom || bottom2) {
      this.setState({
        isScrolledBottom: true,
      });
    }
  };

  handleSubmitDivision = (e) => {
    e.preventDefault();
    const {createDivision} = this.props;
    const {division_name, description} = this.state;
    const params = {
      ...(division_name && {division_name}),
      ...(description && {description}),
    };
    return createDivision(params);
  };


  renderModalFormProfile = () => {
    const {
      classes,
      isLoading,
      isLoadingManager,
      jobData,
      roleData,
      isSuperAdmin,
      roleLoading,
      jobLoading,
      userData,
      managerData,
      divisionDataByOrg,
      divisionLoading,
    } = this.props;
    const {
      name,
      nik,
      division_id,
      address,
      job_title_id,
      email,
      dob,
      join_date,
      phone,
      role_id,
      password,
      imageURL,
      manager,
      user_img,
    } = this.state.formDataProfile;
    const {
      isEditProfile,
      modalFormShowProfile,
      showPasswordProfile,
      errorMessageProfile,
      errorImageSizeProfile,
      showCropResultProfile,
      isCroppingProfile,
      errorMessageEditProfile,
    } = this.state;
    const titleDialog = isEditProfile ? 'Update User' : 'Update User';
    const ManagerData = [];
    // let optionEdit= [];
    userData.map((value) => {
      return ManagerData.push(value.id);
    });

    const renderDivisionOption = () => {
      const divisionList = [];
      divisionDataByOrg.map((value) => {
        divisionList.push({id: value.id, name: value.division_name});
      });
      divisionList.push({id: 0, name: '+ Add New Division...'});
      return divisionList;
    };

    const divisionOption = [];
    divisionDataByOrg.map((data) => {
      divisionOption.push({
        id: data.id,
        name: data.division_name,
      });
    });
    divisionOption.push({id: 0, name: '+ Add New Division...'});

    const manager_id = userData.filter((value) => {
      return value.nik === manager;
    });
    const optionEdit = managerData.filter((value) => {
      return value.name !== name;
    });
    return (
      <Dialog
        open={modalFormShowProfile}
        onClose={() => this.handleFormToggleProfile('close')}
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggleProfile('close')}
        />
        <form onSubmit={this.handleSubmitProfile} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <MInput
              classNameFC={classes.formControl}
              name="name"
              label="Full Name *"
              placeholder="Full Name"
              defaultValue={name}
              onBlur={this.handleInputChange}
              error={errorMessageProfile && errorMessageProfile.name ? errorMessageProfile.name : null}
              autoFocus
            />
            {!isSuperAdmin ? (
              <MInput
                classNameFC={classes.formControl}
                name="nik"
                label="NIK / Employee ID *"
                placeholder="NIK"
                defaultValue={nik}
                onBlur={this.handleInputChange}
                error={errorMessageProfile && errorMessageProfile.nik ? errorMessageProfile.nik : null}
              />
            ) : null}
            {!isSuperAdmin ? (
              <Autocomplete
                className={classes.formControl}
                ListboxProps={{style: {maxHeight: '12rem'}}}
                value={division_id}
                onChange={(event, newValue) => {
                  if (newValue.id === 0) {
                    this.setState({modalDivisionShow: true});
                  } else {
                    this.setState({
                      formDataProfile: {
                        ...this.state.formDataProfile,
                        division_id: newValue,
                      },
                    });
                  }
                }}
                id="controllable-states-demo"
                options={divisionOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Division *"
                    variant='outlined'
                    error={errorMessageProfile && errorMessageProfile.division_id ? true : false}
                    helperText= {errorMessageProfile && errorMessageProfile.division_id ? errorMessageProfile.division_id : null}
                  />
                }
                disableClearable
              />
              ) : (
                ''
              )}
            {!isSuperAdmin ? (
              <MSelect
                classNameFC={classes.formControl}
                name="job_title_id"
                value={job_title_id}
                label="Job Title *"
                onChange={this.handleInputChange}
                error={errorMessageProfile && errorMessageProfile.job_title_id ? errorMessageProfile.job_title_id : null}
                keyPair={['id', 'name']}
                options={jobData ? jobData : []}
                loading={jobLoading}
              />
            ) : (
              ''
            )}
            {!isSuperAdmin ? (
              <Autocomplete
                className={classes.formControl}
                id="free-solo-demo"
                freeSolo
                options={isEditProfile ? optionEdit : managerData}
                value={manager ? {nik: manager.manager_id * 1, name: manager.manager_name} : null}
                getOptionLabel={(option) => (option ? option.name : '')}
                onChange={(event, value) => this.selectHandler(event, value)}
                style={{position: 'relative'}}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Supervisor"
                      variant="outlined"
                      fullWidth
                      onChange={(event, value) => this.searchCombo(event, value)}
                      // onClick={() => this.searchCombo()}
                      error={errorMessageProfile && errorMessageProfile.manager ? true : false}
                      helperText= {errorMessageProfile && errorMessageProfile.manager ? errorMessageProfile.manager : null}
                    />
                    {isLoadingManager ? <CircularProgress color="inherit" size={24} style={{position: 'absolute', right: '35px', top: '15px'}}/> : null}
                  </>
                )}
              />
              ) : ''
            }
            <MInput
              classNameFC={classes.formControl}
              name="email"
              label="Email Address *"
              placeholder="Email"
              type="email"
              defaultValue={email}
              onBlur={this.handleInputChange}
              error={errorMessageProfile && errorMessageProfile.email ? errorMessageProfile.email : null}
              autoComplete="new-email"
            />
            {!isSuperAdmin ? (
              <MInput
                classNameFC={classes.formControl}
                name="address"
                label="Address"
                placeholder="Address"
                type="text"
                defaultValue={address}
                onBlur={this.handleInputChange}
                // error={errorMessageEditProfile && address === null ? 'Full Name is required' : null}
                autoComplete="new-address"
              />
            ) : null}
            {!isSuperAdmin ? (
              <MDatepicker
                className={classes.formControl}
                name="dob"
                label="Date Of Birth *"
                value={dob}
                onDateChange={this.handleDateChange}
                error={errorMessageProfile && errorMessageProfile.dob ? errorMessageProfile.dob : null}
                placeholder="dd-mm-yyyy"
                maxDate={new Date()}
                inputVariant="outlined"
                disableFuture
                openTo="year"
                views={['year', 'month', 'date']}
                fullWidth
              />
            ) : null}
            <MInputMask
              classNameFC={classes.formControl}
              name="phone"
              label="Phone Number *"
              placeholder="Fill in user phone number"
              type="number"
              defaultValue={phone}
              onBlur={this.handleInputChange}
              error={
                errorMessageProfile && errorMessageProfile.phone ?
                  errorMessageProfile.phone.split(':')[0] :
                  undefined
              }
            />
            {!isSuperAdmin ? (
              <MInput
                classNameFC={classes.formControl}
                name="password"
                label="Password *"
                placeholder="***********"
                autoComplete="new-password"
                type={showPasswordProfile ? 'none' : 'password'}
                defaultValue={!isEditProfile ? password : undefined}
                onBlur={this.handleInputChange}
                // error={errorMessageEditProfile && (password === null || password === undefined || password === '') ? 'Password is required' : false}
              />
            ) : null}
            {/* {!isSuperAdmin ? (
              <MDatepicker
                className={classes.formControl}
                name="join_date"
                label="Register Date"
                value={join_date}
                onDateChange={this.handleDateChange}
                error={errorMessageEditProfile && (join_date === null || join_date === undefined || join_date === '') ? 'Register Date is required' : false}
                placeholder="dd-mm-yyyy"
                maxDate={new Date()}
                inputVariant="outlined"
                disableFuture
                openTo="year"
                views={['year', 'month', 'date']}
                fullWidth
              />
            ) : null} */}

            {/* {isSuperAdmin ? (
            <MSelect
              classNameFC={classes.formControl}
              name="role_id"
              label="Role"
              value={role_id}
              onChange={this.handleInputChange}
              error={
                errorMessageProfile && errorMessageProfile.role_id ?
                  errorMessageProfile.role_id :
                  undefined
              }
              keyPair={['id', 'name']}
              options={roleData ? roleData : []}
              loading={roleLoading}
            />) : null } */}
            {!isSuperAdmin ? (
              <>
                <MInput
                  classNameFC={classes.formControl}
                  name="user_img"
                  label="Photo"
                  placeholder="Photo"
                  type="file"
                  onChange={this.handleInputChange}
                  inputProps={{accept: 'image/*'}}
                  error={
                  errorImageSizeProfile ?
                    errorImageSizeProfile :
                    errorMessageProfile && errorMessageProfile.user_img ?
                    errorMessageProfile.user_img :
                    undefined
                  }
                />
                <span><i>Must be .jpg/.jpeg with recommended resolution 200 x 200 px</i></span>
                {!isCroppingProfile && imageURL && (
                  <div>
                    <CardMedia
                      className={classes.photo}
                      title="Photo"
                      image={imageURL}
                    />
                    {user_img && (
                      <MButton
                        style={{marginTop: 10}}
                        className={`${classes.defaultBtn} ${classes.btnCrop}`}
                        label="Retry"
                        icon={<Crop />}
                        onClick={() => this.toggleCropperModal(true)}
                      />
                    )}
                  </div>
                )}
                <ImageCropper
                  showModal={isCroppingProfile}
                  src={user_img}
                  showResult={showCropResultProfile}
                  onClose={() => this.toggleCropperModal(false)}
                  onComplete={this.handleCrop}
                />
              </>
            ) : null}
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggleProfile('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              // loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderModalImport = () => {
    const {classes, importLoading} = this.props;
    const {modalImportShow, file} = this.state;

    const template = window.location.origin+'/download/users-import-template.xlsx';

    return (
      <Dialog
        open={modalImportShow}
        onClose={() => this.handleModalImport('close')}
        maxWidth='md'
      >
        <ModalTitle
          title={'Import User'}
          onClose={() => this.handleModalImport('close')}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <DropzoneArea
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{container: {spacing: 1, direction: 'row'}}}
              previewChipProps={{classes: {root: classes.previewChip}}}
              previewText="Selected files"
              dropzoneText='Drag and drop an Excel (.csv/.xls/.xlsx) file here or click'
              filesLimit={1}
              classes={{root: `${file && file.length > 0 ? classes.hidden : classes.dropZoneArea}`, text: classes.dropZoneText, icon: classes.dropZoneIcon}}
              acceptedFiles={['.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
              onChange={(files) => this.handleImportFile(files)}
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Grid container justify='space-between'>
              <Grid item>
                <a href={template} download target="_blank">
                  <Typography color='primary' style={{fontWeight: 'bold'}}>Download Excel Template</Typography>
                </a>
              </Grid>
              <Grid item spacing={2}>
                <MButton
                  label="Cancel"
                  style={{marginRight: 15}}
                  onClick={() => this.handleModalImport('close')}
                />
                <MButton
                  className={classes.defaultBtn}
                  label={'Import'}
                  type="submit"
                  icon={<Send />}
                  loading={importLoading ? true : false}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  handleFormToggleProfile = (type) => {
    this.setState({
      modalFormShowProfile: false,
    });
  }

  fetchManager = () => {
    const {page, limit, all, searchFilterManager} = this.state;
    const queryParams = {
      query: searchFilterManager,
      // all: true
    };
    this.props.getAllManager(queryParams);
  }

  handleDebounceSearch = _.debounce((query) => {
    this.setState({searchFilterManager: query},
        () => {
          this.fetchManager();
        });
  }, 500);

  selectHandler = (event, value) => {
    if (value) {
      value.manager_id = value.nik;
      value.manager_name = value.name;
    }
    this.setState({
      formDataProfile: {
        ...this.state.formDataProfile,
        manager: value ? value : null,
      },
    });
  }

  resetManager = () => {
    this.setState({searchFilterManager: null});
  }

 handleDivisionForm = (event) => {
   const {name, value} = event.target;
   this.setState({
     [name]: value,
   });
 }


  handleInputChange = (event) => {
    const {formDataProfile, errorMessageProfile} = this.state;
    const {name, value} = event.target;
    if (name === 'user_img') {
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        this.setState({
          isCroppingProfile: true,
          errorImageSizeProfile: null,
          errorMessageProfile: {...errorMessageProfile, user_img: null},
          formDataProfile: {
            ...formDataProfile,
            imageURL: URL.createObjectURL(image),
            user_img: image,
          },
        });
      } else {
        let message;
        if (imageSizeIsAllowed === false) {
          message =
            'Maximum file size is ' +
            process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        }
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSizeProfile: message,
          imageURL: null,
          user_img: null,
        });
      }
    } else {
      this.setState({
        formDataProfile: {...formDataProfile, [name]: value},
        errorMessageProfile: {...errorMessageProfile, [name]: null},
      });
    }
  };

  handleSubmitProfile = (e) => {
    e.preventDefault();
    const {createUser, createNewUser, updateUser, updateNewUser, isSuperAdmin} = this.props;
    const {isEdit} = this.state;
    const {
      id,
      name,
      address,
      nik,
      division_id,
      job_title_id,
      email,
      dob,
      phone,
      join_date,
      role_id,
      manager,
      password,
      cropped_user_img,
    } = this.state.formDataProfile;

    const payload = {
      name: name,
      email: email,
      phone: phone,
    };

    // return console.log(division_id);

    const dataForm = new FormData();
    if (name) {
      dataForm.append('name', name);
    }
    if (nik) dataForm.append('nik', nik);
    dataForm.append('approval_type', 'master');
    if (division_id && division_id.id !== null) dataForm.append('division_id', division_id.id);
    if (!isSuperAdmin && manager) {
      if (manager.manager_id !=='null' && manager.manager_id !==null) {
        dataForm.append('manager', manager.manager_id);
      } else {
        dataForm.append('manager', null);
      }
    } else {
      dataForm.append('manager', null);
    }
    if (!isSuperAdmin && job_title_id) dataForm.append('job_title_id', job_title_id);
    if (email) dataForm.append('email', email);
    if (address) dataForm.append('address', address);
    if (dob) dataForm.append('dob', convDate(dob));
    // if (join_date) dataForm.append('join_date', convDate(join_date));
    if (phone) dataForm.append('phone', phone);
    if (isEdit) {
      if (role_id) dataForm.append('role_id', role_id);
    } else {
      if (!isSuperAdmin) dataForm.append('role_id', isSuperAdmin ? role_id : 3);
    }
    if (password) dataForm.append('password', password);
    if (cropped_user_img) {
      dataForm.append('user_img', blobToFile(cropped_user_img));
    }

    const params = [
      {name: 'name', value: name ? name : null},
      {name: 'nik', value: nik ? nik : null},
      {name: 'job_title_id', value: job_title_id ? job_title_id : null},
      {name: 'address', value: address ? address : null},
      {name: 'email', value: email ? email : null},
      {name: 'dob', value: dob ? dob : null},
      {name: 'phone', value: phone ? phone : null},
      {name: 'join_date', value: join_date ? join_date : null},
      // { name: "password", value: password ? password : null },
    ];

    // for (let i = 0; i < params.length; i++) {
    //   if (params[i].value === null && params[i].name !== 'address' || params[i].value === undefined && params[i].name !== 'address') {
    //     return this.setState({
    //       errorMessageEditProfile: true,
    //     });
    //   }
    // }
    return updateUser(id, dataForm);
    // return !isEdit ? ( !isSuperAdmin ? createUser(dataForm) : createNewUser(payload) ) : (!isSuperAdmin ? updateUser(id, dataForm) : updateNewUser(id, payload));
  };

  handleDateChange = (params) => {
    const {formDataProfile, errorMessageProfile} = this.state;
    const {name, value} = params;
    this.setState({
      formDataProfile: {...formDataProfile, [name]: value},
      errorMessageProfile: {...errorMessageProfile, [name]: null},
    });
  };

  handleGetJobInfo = (jobType) => {
    const job = this.props.jobData.filter((item) => item.name === jobType);
    return job.length ? job : null;
  };

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  toggleExportFile = () => {
    this.setState((prevState) => ({
      ...prevState,
      isExportFile: !prevState.isExportFile,
    }));
  }

  toggleImportFile = () => {
    this.setState((prevState) => ({
      ...prevState,
      isImportFile: !prevState.isImportFile,
    }));
  }

  showExportNotif = () => {
    this.setState((prevState) => ({
      ...prevState,
      isExportFile: true,
    }));
  }

  showImportNotif = () => {
    this.setState((prevState) => ({
      ...prevState,
      isImportFile: true,
    }));
  }

  getRefreshToken = async () => {
    try {
      this.props.updateRefreshToken({
        refreshToken: localStorage.getItem('refreshToken'),
      });
    } catch (error) {
      console.log('Error refreshing token:', error);
    }
  }

  handleAuthError = async (error) => {
    const originalRequest = error.config;
    const {isRefreshToken} = this.state;

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshToken) {
        this.setState({
          isRefreshToken: true,
        });
        try {
          const token = this.getRefreshToken;
        } catch (err) {
          console.log(err);
        }
      }

      if (!this.refreshPromise) {
        this.refreshPromise = this.getRefreshToken().finally(this.clearPromise);
      }

      const token = await this.getRefreshToken;
    }
    return Promise.reject(error);
  };

  render() {
    const {classes} = this.props;
    const {showUpgradeModal, showMethod, invoiceUrl, userFeature, isDrawerOpen} = this.state;

    // if(!this.props.isSuperAdmin){
    //   if(isObject(userFeature)){

    //     if(!userFeature.is_tenant_management_valid){
    //       this.props.logout();
    //       this.props.history.push('/login');
    //     }
    //   }
    // }

    return (
        <Box className={classes.root}>
          <CssBaseline />
          { this.renderAppBar() }
          { this.renderDrawer() }
          { this.renderModalCreateDivision() }
          {this.props.location.pathname !=='/user-management' ? this.renderModalFormProfile() : null}
          <Box className={clsx(classes.rootWhenOpen, !isDrawerOpen && classes.rootWhenClosed)}>
            <div className={classes.contentWrapper}>
              <div className={classes.content}>
                <div
                  className={classes.appBarSpacer}
                  style={{marginTop: impersonateHrMode(this.state?.profileData?.id) ? 20 : 0}}
                />
                {this.props.children}
                <div
                  className={classes.appBarSpacer}
                  style={{marginBottom: impersonateHrMode(this.state?.profileData?.id) ? 20 : 0}}
                />
              </div>
              { this.renderCopyRightMain() }
            </div>
            <div className={classes.wrapperFloating}>
              {this.state.isExportFile && (
                <ExportFloatingCard handleClose={this.toggleExportFile} />
              )}
              {this.state.isImportFile && (
                <ImportFloatingCard handleClose={this.toggleImportFile} />
              )}
            </div>
            <UpgradeAccountModal
              isOpen={showUpgradeModal}
              showMethod={showMethod}
              handleParentToggle={this.toggleModalUpgrade}
              invoiceUrl={invoiceUrl}
            />
          </Box>
        </Box>
    );
  }
}

const stateToProps = ({auth, jobTitle, upgradeAccount, organization, userManagement, invoice, xendit, withdraw, divisionManagement, notification, attendanceHistory, refreshToken}) => ({
  auth: auth,
  userManagement: userManagement,
  token: auth.data.accessToken,
  isSuperAdmin: checkSuperadmin(auth),
  isPremium: upgradeAccount.premium,
  isTrial: upgradeAccount.trial,
  isPaid: upgradeAccount.payment,
  loadingPremium: upgradeAccount.isLoading,
  expiredMessage: upgradeAccount.expiredMessage,
  paymentMethod: upgradeAccount.paymentMethod,
  role: auth.data.profile.role,
  profile: auth.data.profile,
  terms_and_condition: auth.data,
  data: auth.data,
  version: auth.version,
  organization: auth.data.organization,
  isSuccessOrganization: organization.isSuccess,
  triggerOrganization: organization.trigger,
  messageOrganization: organization.message,
  orgStatusData: organization.orgStatusData,
  attendanceTypes: userManagement.attendanceTypes,
  menusData: auth.data.menus,
  price: invoice.total,
  expiredDate: upgradeAccount.expiredDate,
  dueDate: upgradeAccount.dueDate,
  statusData: upgradeAccount.data,
  isSuccess: upgradeAccount.isSuccess,
  isError: upgradeAccount.isError,
  trigger: upgradeAccount.trigger,
  message: upgradeAccount.message,
  xenditStatus: xendit.xenditStatus,
  isSuccessXendit: xendit.isSuccess,
  triggerXendit: xendit.trigger,
  messageXendit: xendit.message,
  isErrorXendit: xendit.isError,
  isLoadingXendit: xendit.isLoading,
  isLoadingUser: userManagement.isLoading,
  userFeature: userManagement.menus,

  isLoadingProfile: userManagement.isLoadingProfile,
  isLoadingManager: userManagement.isLoadingManager,
  isLoading: userManagement.isLoading,
  isError: userManagement.isError,
  isErrorProfile: userManagement.isErrorProfile,
  isSuccess: userManagement.isSuccess,
  isSuccessProfile: userManagement.isSuccessProfile,
  message: userManagement.message,
  messageProfile: userManagement.messageProfile,
  trigger: userManagement.trigger,
  triggerProfile: userManagement.triggerProfile,
  profileData: userManagement.profileData,
  roleLoading: userManagement.roleLoading,
  roleData: userManagement.roleData,
  managerData: userManagement.managerData,

  userTrigger: userManagement.trigger,
  userIsSuccess: userManagement.isSuccess,
  userIsError: userManagement.IsError,
  userMessage: userManagement.message,
  userData: userManagement.userData,
  userMeta: userManagement.userMeta,

  jobLoading: jobTitle.jobLoading,
  jobData: jobTitle.jobDataByOrg,

  isLoadingWithdraw: withdraw.isLoading,
  isErrorWithdraw: withdraw.isError,
  isSuccessWithdraw: withdraw.isSuccess,
  messageWithdraw: withdraw.message,
  triggerWithdraw: withdraw.trigger,

  isLoadingDivision: divisionManagement.isLoading,
  isErrorDivision: divisionManagement.isError,
  isSuccessDivision: divisionManagement.isSuccess,
  divisionMessage: divisionManagement.message,
  triggerDivision: divisionManagement.trigger,
  divisionDataByOrg: divisionManagement.divisionDataByOrg,
  divisionMeta: divisionManagement.divisionMeta,

  isSuccessTerms: organization.isSuccessTerms,
  isErrorTerms: organization.isErrorTerms,
  isLoadingTerms: organization.isLoadingTerms,
  triggerTerms: organization.triggerTerms,
  messageTerms: organization.messageTerms,

  isLoadingNotification: notification.isSuccess,
  isSuccessNotification: notification.isSuccess,
  isErrorNotification: notification.isError,
  triggerNotification: notification.trigger,
  notificationData: notification.notificationData,

  attendanceExport: attendanceHistory.overtimeData,
  triggerAttendance: attendanceHistory.trigger,

  refreshToken: refreshToken.data,
  triggerToken: refreshToken.trigger,
});

const dispatchToProps = (dispatch) => ({
  getAllManager: (params) => dispatch(getAllManager(params)),
  getProfile: (params) => dispatch(getProfile(params)),
  getAllRole: () => dispatch(getAllRole()),
  getOneManager: (id) => dispatch(getOneManager(id)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
  clearState: () => dispatch(clearState()),
  clearWithdraw: () => dispatch(clearWithdraw()),
  getNewUsers: (params) => dispatch(getNewUsers(params)),
  getJobTitleByOrg: (params) => dispatch(getJobTitleByOrg(params)),
  logout: () => dispatch(logout()),
  checkPremium: () => dispatch(checkPremium()),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  checkAmount: () => dispatch(checkAmount()),
  getInvoicePrice: () => dispatch(getInvoicePrice()),
  checkXenditStatus: (params) => dispatch(checkXenditStatus(params)),
  readNotifUser: () => dispatch(readNotifUser()),

  getDivisionByOrg: (params) => dispatch(getDivisionByOrg(params)),
  createDivision: (params) => dispatch(createDivision(params)),
  clearStateDivision: () => dispatch(clearState()),
  acceptTermsAndCondition: (params) => dispatch(acceptTermsAndCondition(params)),

  getNotification: (params) => dispatch(getNotification(params)),
  readNotification: (id, params) => dispatch(readNotification(id, params)),
  clearStateNotification: () => dispatch(clearStateNotification()),

  updateExportNotification: (params) => dispatch(updateExportNotification(params)),
  updateRefreshToken: (params) => dispatch(updateRefreshToken(params)),
  clearRefreshToken: () => dispatch(clearRefreshToken()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(withRouter(Container)));
